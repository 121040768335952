<template>
	<div class="page">
		<el-form class="page card-box" ref="form" :label-position="'top'">
			<el-row :gutter="20">
				<el-col :span="24">
					<el-card shadow="hover">
						<el-col :xs="24" :sm="24" :md="6" :lg="3" :xl="3">
							<el-form-item :label="$t('statistika.linija')">
								<el-select v-model="linijaId" @change="podaciZaBar(null, linijaId)">
									<el-option v-for="val in arrayLinije" :key="val" :value="val" :label="val"></el-option>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :xs="24" :sm="24" :md="6" :lg="3" :xl="3">
							<el-form-item :label="$t('statistika.pravac')">
								<el-select v-model="odakleIde" @change="podaciZaBar(null, linijaId)">
									<el-option label="SRB" value="SRB"/>
									<el-option label="CH" value="CH"/>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :xs="24" :sm="24" :md="6" :lg="4" :xl="4">
							<el-form-item :label="$t('statistika.datum_od')">
								<el-date-picker
										@change="podaciZaBar(null, linijaId, datum_od, '')"
										:picker-options="pickerOptions"
										v-model="datum_od"
										:clearable="false"
										:editable="false"
										type="date"
										format="dd.MM.yyyy">
								</el-date-picker>
							</el-form-item>
						</el-col>
						<el-col :xs="24" :sm="24" :md="6" :lg="4" :xl="4">
							<el-form-item :label="$t('statistika.datum_do')">
								<el-date-picker
										@change="podaciZaBar(null, linijaId, '', datum_do)"
										:picker-options="pickerOptions"
										v-model="datum_do"
										:clearable="false"
										:editable="false"
										type="date"
										format="dd.MM.yyyy">
								</el-date-picker>
							</el-form-item>
						</el-col>
						<el-col>
							<v-chart style="width: auto" :autoresize="true" :options="vBarMap"/>
						</el-col>
					</el-card>
				</el-col>
			</el-row>
		</el-form>
	</div>
</template>

<script>
export default {
	name: 'statistika',
	data() {
		return {
			pickerOptions: {
				firstDayOfWeek: 1
			},
			datum_od: null,
			datum_do: null,
			arrayLinije: [],
			dataLinije: [],
			linijaId: null,
			odakleIde: 'SRB',
			labelOption: {
				show: true,
				fontSize: 16,
				rich: {
					name: {
						textBorderColor: '#fff'
					}
				}
			},
			vBarMap: {
				color: ['#003366', '#006699', '#4cabce', '#e5323e'],
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						type: 'shadow'
					}
				},
				legend: {
					data: [this.$t('statistika.broj_karata'), this.$t('statistika.ukupna_cena'), this.$t('statistika.ukupno_putnika'), this.$t('statistika.vrsta_karte')]
				},
				xAxis: {
					type: 'category',
					axisTick: { show: false },
					data: []
				},
				yAxis: [
					{
						type: 'value'
					}
				],
				series: []
			}
		};
	},
	mounted() {
		this.$store.dispatch('setHeader', {
			title: this.$t('dashboard.hello')
		});

		this.$utils('stopLoadingAfter', [this.statistikaProdateKarte()]);
	},
	methods: {
		statistikaProdateKarte() {
			return this.$get('statistikaProdateKarte').then(data => {
				this.dataLinije = data;
				this.podaciZaBar(data);
			});
		},
		podaciZaBar(data = null, linija = null, datumOd = null, datumDo = null) {
			data = data || this.dataLinije;
			this.arrayLinije = Object.keys(data);
			this.linijaId = linija || this.arrayLinije[0];
			this.datum_od = null;
			this.datum_do = null;
			let datumi = [];
			let series = [];
			let brojKarata = [];
			let ukupnaCena = [];
			let ukupnoPutnika = [];
			let vrstaKarte = [];
			if (datumOd)
				this.datum_od = this.moment(datumOd);

			if (datumDo)
				this.datum_do = this.moment(datumDo);

			data[this.linijaId] = _.sortBy(data[this.linijaId], 'datum', 'asc');

			data[this.linijaId].forEach((d) => {
				if (!this.datum_od)
					this.datum_od = this.moment(d.datum);
				if (!datumDo)
					this.datum_do = this.moment(d.datum);

				if (this.moment(d.datum).format() >= this.moment(this.datum_od).format() && this.moment(d.datum).format() <= this.moment(this.datum_do).format()) {
					if (d.SRBcheck === this.odakleIde) {
						datumi.push(this.$utils('formatDate', this.moment(d.datum)));
						brojKarata.push(d.broj_karata);
						ukupnaCena.push(d.ukupna_cena);
						ukupnoPutnika.push(d.ukupno_putnika);
						vrstaKarte.push(d.vrsta_karte);
					}
				}
			});
			series.push({
				name: this.$t('statistika.broj_karata'),
				type: 'bar',
				label: this.labelOption,
				data: brojKarata
			});
			series.push({
				name: this.$t('statistika.ukupna_cena'),
				type: 'bar',
				label: this.labelOption,
				data: ukupnaCena
			});
			series.push({
				name: this.$t('statistika.ukupno_putnika'),
				type: 'bar',
				label: this.labelOption,
				data: ukupnoPutnika
			});
			series.push({
				name: this.$t('statistika.vrsta_karte'),
				type: 'bar',
				label: this.labelOption,
				data: vrstaKarte
			});
			this.vBarMap.xAxis.data = datumi;
			this.vBarMap.series = series;
		}
	}
};
</script>

<style lang="scss" scoped>
</style>
